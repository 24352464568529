.announcement-list {
	.announcement-item {
		&:last-child {
			border-bottom: none;
		}
	}
}

.announcement-item {
	padding: 10px 10px 15px 10px;
	border-bottom: 1px solid #d8d8d8;
}

.announcement-item__headline {
	display: block;
	margin: 0 0 10px 0;
	font-weight: bold;
	font-size: 15px;
	color: #231f27;
}

.announcement-item__description {
	color: #979696;
	margin-bottom: 10px;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}
}


.announcement-item__cover {
	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}
}
