$icon-color: #8D0000;

.icon-play {
	width: 40px;
	height: 40px;
	fill:#8d0000;
	display: inline-block;
	vertical-align: middle;
}


.arrow-down {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 8px solid #fff;
}


.icon-map {
	fill: $icon-color;
	width: 30px;
	height: 30px;
}


.icon-arrow-left {
	fill:#231F27;
}


.icon-dropdown {
	fill:#D3CBD6;
	width: 15px;
	height: 15px;
	cursor: pointer;
}


.icon-globe {
	fill: #8D0000;
	width: 20px;
	height: 20px;
}


.icon-favorites {
	display: inline-block;
	vertical-align: top;
	width: 28px;
	height: 40px;

	.icon-favorites__bg {
		fill:#d7d9db;
	}

	.icon-favorites__star {
		fill:#ffffff;
	}

	&.active {

		.icon-favorites__bg {
			fill:#8D0000;
		}

		.icon-favorites__star {
			fill:#FEA000;
		}
	}

}

.icon-arrow-bottom {
	width: 11px;
	max-height: 6px;
	display: inline-block;
}
