.breadcrumb {
	padding: 15px 10px;
	margin: 0;
	list-style: none;
	border-bottom: 1px solid #e8e8e8;
	color: #231f27;
	font-size: 0;

	a {
		color: #7d7d7d;
	}

	li {
		padding-left: 20px;
		font-size: 13px;
		display: inline;
		vertical-align: top;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 4px;
			height: 6px;
			background: url(../images/icon-arrow-right.png) no-repeat;
			background-size: 4px 6px;
			position: absolute;
			top: 5px;
			left: 8px;
		}

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}
	}
}


.form-search {
	position: relative;
	margin-bottom: 20px;

	.form-control {
		padding-right: 40px;
		vertical-align: top;
	}
}


.form-search__btn {
	position: absolute;
	top: 1px;
	right: 1px;
	width: 40px;
	height: 38px;
	overflow: hidden;
	border: 0;
	background-color: #fff;
	font-size: 0;
	text-align: center;
	line-height: 30px;

	.icon {
		width: 20px;
		height: 20px;
		display: inline-block;
		fill:#d8d0db;
		vertical-align: middle;
	}

}


.panel__button {
	margin-bottom: 30px;
	text-align: center;

	.btn {
		width: 100%;
	}
}


.blog__headline {
	display: block;
	font-size: 20px;
	line-height: 1;
	margin: 0 0 20px 0;
	text-align: center;
}

.blog__information-line {
	padding: 0;
	margin: 0 0 15px 0;
	text-align: center;
	color: #878585;
	font-size: 0;

	li {
		font-size: 12px;
		line-height: 13px;
		display: inline-block;
		vertical-align: top;
		position: relative;

		&:before {
			content:"/";
			display: inline-block;
			vertical-align: top;
			margin: 0 10px
		}

		&:first-child:before {
			display: none;
		}
	}

	a {
		color: #878585;
	}

	.icon {
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		fill:#878585;
		overflow: hidden;
	}

	.icon-eye {
		width: 15px;
		height: 8px;
		margin-top: 1px;
	}

	.icon-comments {
		width: 13px;
		height: 10px;
	}
}

.blog__content {
	font-size: 13px;
	line-height: 18px;
	margin-bottom: 30px;
	color: #858484;

	img {
		max-width: 100%;
		height: auto;
		display: block;
		margin: 15px auto;
	}

	p {
		margin: 10px 0;
	}
}


.blog__article-tags {
	font-size: 12px;
	color: #2b292f;
	margin-bottom: 20px;

	strong {
		color: #2b292f;
		font-size: 14px;
	}

	a {
		color: #848383;
		text-transform: uppercase;
	}
}

.blog__tags {
	margin-bottom: 30px;
}

.blog__tags-list {
	text-transform: uppercase;

	a {
		color: #868484;
		font-size: 12px;
		line-height: 16px;
		display: inline-block;
		vertical-align: top;
		margin: 0 30px 10px 0;
	}
}


.blog__popular-articles {
	margin-bottom: 40px;
}

.popular-articles {

	.popular-article__item:last-child {
		margin-bottom: 0;
	}
}

.popular-article__item {
	overflow: hidden;
	margin-bottom: 20px;
	display: block;
}

.popular-article__img {
	float: left;
	width: 100px;
	margin: 0 10px 0 0;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.popular-article__info {
	overflow: hidden;

	strong {
		display: block;
		font-size: 13px;
		line-height: 16px;
		font-weight: normal;
		color: #27242b;
	}

	time {
		display: block;
		color: #878585;
	}
}

.blog__share {
	margin-bottom: 30px;
}

.blog__share-example {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	height: 30px;
}

.blog__comments {
	margin-bottom: 30px;
}


.blog__comments-example {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	height: 130px;
}

.blog__nav {
	overflow: hidden;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 40px;

	.inner {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.blog__nav-full {
	margin-left: -10px;
	margin-right: -10px;

	.inner {
		margin-left: 0;
		margin-right: 0;
	}
}


.blog__nav-link {
	width: 50%;
	padding: 0 10px;

	time {
		display: block;
		font-size: 12px;
		line-height: 16px;
		position: relative;
		color: #878585;
	}

	strong {
		display: block;
		color: #231f27;
		font-size: 13px;
		line-height: 16px;
	}

	.icon {
		position: absolute;
		top: 4px;
		width: 6px;
		height: 7px;
		fill:#878585;
		display: block;
		overflow: hidden;
	}
}

.blog__nav-prev {
	float: left;

	time {
		padding-left: 15px;
	}

	.icon {
		left: 0;
	}
}

.blog__nav-next {
	float: right;
	text-align: right;

	time {
		padding-right: 15px;
	}

	.icon {
		right: 0;
	}
}


.blog__h2 {
	text-align: center;
	display: block;
	font-weight: bold;
	color: #27242b;
	font-size: 18px;
	line-height: 1;
	margin: 0 0 25px 0;
}


.blog-articles {
	padding: 0;
	margin-bottom: 30px;
	font-size: 0;
}

.blog-article__item {
	display: inline-block;
	vertical-align: top;
	padding-left: 10px;
	padding-right: 10px;
	width: 50%;
	font-size: 13px;
	margin-bottom: 10px;
}


.blog-article__item {

	figure {
		margin: 0 0 10px 0;

		img {
			vertical-align: top;
			max-width: 100%;
			height: auto;
		}
	}

	span,
	time {
		display: block;
		line-height: 16px;
	}

	span {
		font-size: 13px;
		color: #27242b;
	}

	time {
		font-size: 11px;
		color: #878585;
	}
}


.blog-article__link {
	display: inline-block;
	vertical-align: top;
}


.announcements {
	overflow: hidden;
	margin-bottom: 40px;

	.announcements__item:first-child {
		padding-top: 0;
	}

	.announcements__item:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}
}

.announcements__item {
	border-bottom: 1px solid #e9e9e9;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 12px;
	color: #878585;
	display: block;
}


.announcements__description {
	font-size: 12px;
	line-height: 14px;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}
}


.announcements__header {
	margin-bottom: 10px;
	overflow: hidden;

	strong {
		font-size: 14px;
		line-height: 16px;
		display: block;
		font-weight: normal;
		color: #27242b;
		overflow: hidden;
	}

	figure {
		float: left;
		margin: 0 10px 0 0;
		width: 100px;

		img {
			max-width: 100%;
			height: auto;
			vertical-align: top;
		}
	}
}


.blog__subscription {
	margin-bottom: 40px;
}


.form-subscription {
	position: relative;

	.form-control {
		padding-right: 40px;
		vertical-align: top;
	}
}


.form-subscription__btn {
	position: absolute;
	top: 1px;
	right: 1px;
	width: 40px;
	height: 38px;
	overflow: hidden;
	border: 0;
	background-color: #fff;
	font-size: 0;
	text-align: center;
	line-height: 30px;

	.icon {
		width: 20px;
		height: 20px;
		display: inline-block;
		fill:#d8d0db;
		vertical-align: middle;
	}

}


.list-category {
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		border-bottom: 1px solid #d8d8d8;
	}

	a {
		display: block;
		padding: 15px 10px;
		color: #27242b;
		font-size: 14px;
		line-height: 1;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		border-top: 1px solid #d8d8d8;
		display: none;

		li {
			padding-left: 20px;
		}

		li:last-child {
			border-bottom: none;
		}
	}
}

.list-category__sub {
	padding-right: 35px;
	position: relative;

	&:before {
		content:"";
		display: block;
		position: absolute;
		top: 20px;
		right: 10px;
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid #27242b;
	}

	&.active:before {
		top: 12px;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid transparent;
		border-bottom: 7px solid #27242b;
	}
}


.article-list {
	margin-bottom: 40px;

	.article-item {
		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
}

.article-item {
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}

.article-item__img {
	margin: 0;
	float: left;
	width: 100px;
	margin-right: 10px;

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

.article-item-full {
	.article-item__img {
		margin-right: 0;
		margin-bottom: 10px;

		img {
			margin: 0 auto;
		}
	}

	.article-item__headline {
		font-size: 20px;
	}

	.article-item__img {
		float: none;
		width: 100%;
	}
}

.article-item__description {
	color: #848383;
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 10px;

	p {
		margin: 0;
	}
}

.link-more {
	display: inline-block;
	vertical-align: top;
	font-size: 12px;
}

.article-item__header {
	margin-bottom: 10px;

	.blog__information-line {
		text-align: left;
		margin: 0;
	}
}

.article-item__headline {
	display: block;
	font-weight: 700;
	color: #27242b;
	font-size: 18px;
	line-height: 1;
	margin-bottom: 5px;

	a {
		color: #000;
	}
}


.article-item__group {
	overflow: hidden;
}


.article-item__info {
	@extend .blog__information-line;

	li {
		&:before {
			content:"/";
			display: inline-block;
			vertical-align: top;
			margin: 0 5px

		}
	}
}


.pagination {
	padding: 0;
	margin: 10px 0;
	list-style: none;
	font-size: 0;

	li {
		margin-right: 5px;
		font-size: 12px;
		color: #575757;
		display: inline-block;
		vertical-align: baseline;

		&.disabled {
			display: none;
		}
	}

	a {
		color: #575757;
		display: inline-block;
		vertical-align: baseline;
		height: 20px;
		line-height: 20px;
		text-align: center;
		padding: 0 5px;
	}

	.icon {
		display: inline-block;
		vertical-align: middle;
		fiil:#575757;
		width: 7px;
		height: 7px;
	}
}




.pagination__current {
	a {
		border: 1px solid #e8e8e8;
		border-radius: 2px;
		pointer-events: none;
		cursor: default;
	}
}
