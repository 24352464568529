.table-default {
	width: 100%;
	color: #878585;

	td {
		padding: 10px;
		vertical-align: top;
	}

	tr {
		border-bottom: 1px solid #d8d8d8;
	}
}

.td-label {
	color: #27242b;
	width: 130px;
}

.table-info {
	color: #b4b4b4;
	text-align: center;
	padding: 15px 50px;

	p {
		margin: 0;
	}
}

.tables-wrapper {
	display: inline-block;
	margin-left: -3px;
}

.table-counter {
	display: inline-block;
	margin: 0 2px 0 3px;
}

.btn-minus,
.btn-plus {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #b4b4b4;
	border-radius: 50%;
	position: relative;
	z-index: 2;
	vertical-align: middle;
	&:before {
		content: "";
		position: absolute;
		background: #b4b4b4;
		width: 8px;
		height: 1.5px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
}

.btn-plus {
	&:after {
		content: "";
		position: absolute;
		background: #b4b4b4;
		width: 1.5px;
		height: 8px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
}












