.top-line {
	background: #e8ebf0;
	padding: 15px 10px;
	border-bottom: 1px solid #dbdbdb;

	.headline {
		font-size: 13px;
		color: #231f27;
		font-weight: bold;
		display: block;

		a {
			color: #231f27;
		}

		.icon-arrow-left {
			float: left;
			width: 7px;
			height: 10px;
			margin-right: 5px;
			margin-top: 3px;
		}

		.icon-map {
			margin-top: -8px;
		}

	}
}

.panel {
	padding: 20px 10px;
}

.panel__info {
	font-size: 12px;
	line-height: 14px;
	color: #878585;
	margin-bottom: 20px;

	p {
		margin: 0 0 10px 0;
	}
}

.panel__info-color {
	color: #231f27;
}


.form-control {
	border: 1px solid #d8d8d8;
	-webkit-appearance: none;
	border-radius: 0;
	width: 100%;
	background-color: #fff;
	padding: 12px 20px;
	font-size: 12px;

	&.error {
		border: 1px solid #8d0000;
		color:#272727;
	}
}

textarea.form-control {
	height: 100px;
	resize: none;
}

::-webkit-input-placeholder {color: #878585}
::-moz-placeholder {color: #878585}
:-moz-placeholder {color: #878585}
:-ms-input-placeholder {color: #878585}

:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder {color: transparent}
:focus:-moz-placeholder {color: transparent}
:focus:-ms-input-placeholder {color: transparent}


.btn {
	display: inline-block;
	vertical-align: middle;
	border: 1px solid $btn-bg;
	background-color: $btn-bg;
	padding: 11px 25px;
	color: $btn-color;
	cursor: pointer;
	font-size: 14px;
}

.btn-primary {
	background-color: #ffffff;
	border: 1px solid #900000;
	color: #900000;
}


.login__social-accounts {
	margin-bottom: 40px;

	.headline {
		color: #231f27;
		font-size: 13px;
		font-weight: bold;
		text-align: center;
		position: relative;
		background: #fff;
		margin-bottom: 20px;

		span {
			display: inline-block;
			vertical-align: middle;
			background: #fff;
			padding: 0 10px;
			position: relative;
			z-index: 2;
		}

		&:before {
			content:"";
			display: block;
			height: 1px;
			background: #d8d8d8;
			width: 100%;
			position: absolute;
			top: 50%;
		}
	}
}


.social-link {
	display: block;
	color: #fff;
	font-size: 11px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	white-space: nowrap;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;

	.icon {
		display: inline-block;
		vertical-align: middle;
		width: 25px;
		height: 25px;
	}
}

.social-link__facebook {
	background-color: #4c699e;

	.icon {
		margin-top: -3px;
	}
}

.social-link__google-plus {
	background-color: #db573b;

	.icon {
		width: 30px;
		height: 30px;
	}
}

.social-link__vkontakte {
	background-color: #4c74a3;

	.icon {
		margin-top: -3px;
	}
}

.registration {
	border-bottom: 1px solid #d8d8d8;

	.login__social-accounts {
		margin-bottom: 20px;
	}

	.form-container {
		margin-bottom: 40px;
	}
}


.form-container {

	.form-line {
		margin-bottom: 20px;
	}
}


.form-line {

	span.error {
		display: block;
		color: #8d0000;
		font-size: 11px;
		margin-top: 10px;
	}


	.label {
		display: block;
		margin-bottom: 10px;
		font-size: 11px;
		color: #7d7b7b;

		i {
			font-style: normal;
			color: #8d0000;
		}
	}


	.label-column {
		@extend .row;
		font-size: 11px;
		color: #7d7b7b;

		.range-value {
			margin-top: -4px;
		}
	}

	.range-value {
		font-size: 15px;

		strong {
			color: #990000;
		}
	}
}

.panel-color {
	background-color: $panel-color-bg;
	padding: 15px 10px;
	color: #838282;
}


.panel-content {
	font-size: 12px;
	line-height: 1.2;
	color: #979696;

	p {
		margin: 0;

		+ p {
			margin: 10px 0;
		}
	}
}

.content {
	font-size: 12px;
	line-height: 1.2;

	h2,.h2 {
		display: block;
		margin-bottom: 10px;
		color: #000;
		font-weight: bold;
		font-size: 15px;
	}

	p {
		margin: 10px 0;
	}

	mark {
		background-color: transparent;
		text-align: center;
		font-weight: bold;
		text-align: center;
		display: block;
		color: #838282;
		margin: 15px 0;
	}
}


.link-video {
	text-align: center;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	margin: 20px 0 40px;
}


.no-account {
	margin: 25px auto;
	max-width: 200px;

	.h2,h2 {
		margin-bottom: 20px;
	}
}



.checkbox {
	vertical-align: top;
	margin: 0 3px 0 0;
	width: 15px;
	height: 15px;
}

.checkbox + label {
	cursor: pointer;
	color: #878585;
	display: inline-block;
	vertical-align: top;
}

.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox:not(checked) + label {
  position: relative;
  padding: 0 0 0 25px;
}
.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #878585;
}

.checkbox:checked + label:before {
	background-image: url(../images/svg/icon-checked.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	border: 1px solid #16af2a;
}

.checkbox:checked + label {
	color: #17b02a;
}


.form-element-clear {
	position: relative;

	.form-control {
		padding-right: 40px;
	}

	.icon-clear {
		position: absolute;
		fill:#a1a1a6;
		top: 11px;
		right: 11px;
		cursor: pointer;
		width: 18px;
		height: 18px;
	}
}

.group-blocks {

}

.group-block__item {
	display: none;
}

.group-block__item-current {
	display: block;
}


.group-line-btn {
	@extend .row;
}

.form-container {
	.group-line-btn {
		margin-bottom: 0;
	}
}



.check-in {
	.panel__info {
		padding: 20px 10px 10px 10px;
		border-bottom: 1px solid #d8d8d8;
		margin: 0;
	}
}

.check-in__confirm {
	padding: 16px 10px;
	overflow: hidden;

	.custom-checkbox {
		float: right;
		margin: -6px 0 -6px 20px;
	}
}


.check-in__confirm-title {
	font-size: 14px;
	color: #900000;
	font-weight: bold;
	overflow: hidden;
}


.group-block__item {
	.user-panel__avatar {
		float: none;
		margin: 0 auto;
		border: none;
	}
}

.user-panel__avatar-label {
	text-align: center;
	margin: 5px 0 0 0;
	color: #878585;
	font-size: 12px;
	display: block;
}
