.navigation {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 0;

	> li {
		display: inline-block;
		vertical-align: top;
		border-left: 1px solid #dbdbdb;
	}

	.top-link {
		display: block;
		height: 50px;
		text-align: center;
		line-height: 50px;
		width: 50px;
		overflow: hidden;

		.icon {
			width: 20px;
			height: 20px;
			vertical-align: middle;
			display: inline-block;
		}

		.icon-close {
			width: 20px;
			height: 20px;
			vertical-align: middle;
			display: inline-block;
			position: absolute;
			top: -200px;
			fill:#fff;
		}

		&.open {
			background: #262021;

			.icon {
				fill:#ffffff;
			}

			.icon-hide {
				display: none;
			}

			.icon-close {
				top: auto;
				position: static;
			}
		}
	}
}


.panel-dropdown-search {
	position: absolute;
	top: 50px;
	z-index: 9;
	left: 0;
	width: 100%;
	background: #262021;
	display: none;
	padding: 10px;

	&.open {
		display: block;
	}

	.b-search {
		text-align: left;
		color: #fff;


		.form-control {
			height: 30px;
			border-radius: 5px;
			color: #000;
			font-size: 14px;
		}

		.btn-search {
			height: 30px;
			width: 30px;
			background: url(../images/svg/icon-search-white.svg) 50% 50% no-repeat;
			background-color: #90191c;
			background-size: 15px 15px;
			border-radius: 5px;
		}

		.label {
			font-size: 22px;
			margin-bottom: 5px;
		}

		p,
		.label {
			text-align: left;
		}

		p {
			font-size: 11px;
			color: #959595;
			padding: 0;
		}
	}
}

.panel-dropdown {
	position: fixed;
	z-index: 9;
	top: 50px;
	background: #262021;
	left: 0;
	height: 100%;
	width: 100%;
	padding-bottom: 50px;
	overflow: hidden;
	-webkit-backface-visibility:hidden;
	display: none;
	box-shadow: 0 100px 0 #262021;

	&.open {
		display: block;
	}

	.panel-scroll {
		-webkit-overflow-scrolling-y: touch;
		overflow: hidden;
		width: 100%;
		overflow-y: auto;
		height: 100%;
	}
}

.range-wrapper {
	.label {
		float: left;
		width: 200px;
	}
}

.sub-menu {
	padding: 0;
	margin: 0;
	list-style: none;
	background-color: #1c1718;
	font-size: 14px;

	li {
		border-bottom: 1px solid #353030;
	}

	a {
		color: #fff;
		display: block;
		padding: 12px 30px 12px 60px;
		position: relative;
	}

	.avatar {
		border:1px solid #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		position: absolute;
		overflow: hidden;
		top: 10px;
		left: 20px;

		img {
			width: 100%;
			height: auto;
			vertical-align: middle;
		}
	}

	.icon {
		width: 20px;
		height: 20px;
		display: block;
		fill:#fff;
		position: absolute;
		top: 10px;
		left: 20px;
	}

	.icon-stroke {
		stroke: #fff;
	}
}


.dropdown-menu {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 14px;
}


.dropdown-menu__line {
	padding: 0 20px;
	position: relative;
	min-height: 40px;
	border-bottom: 1px solid #353030;

	.link-dropdown {
		position: absolute;
		top: 50%;
		right: 10px;
		width: 30px;
		text-align: center;
		line-height: 30px;
		height: 30px;
		margin-top: -15px;

		.arrow-down {
			display: inline-block;
			vertical-align: middle;
		}
	}
}


.b-links {
	@include make-row();
	color: #fff;
	font-size: 12px;

	.link {
		height: 55px;
		line-height: 55px;
		text-align: center;
		border-bottom: 1px solid #353030;
		color: #949494;
		border-left: 1px solid #353030;

		&:first-child {
			border-left: none;
		}
	}

	.middle {
		display: inline-block;
		vertical-align: middle;
		padding: 0 20px 0 70px;
		position: relative;
		text-align: left;
		line-height: 1;
	}

	.icon {
		width: 20px;
		height: 20px;
		overflow: hidden;
		display: block;
		position: absolute;
		top: 50%;
		left: 30px;
		margin-top: -10px;
	}

	.icon-desktop {
		width: 20px;
		height: 17px;
		margin-top: -8px;
	}

	.icon-mobile {
		width: 13px;
		height: 20px;
	}

	.icon-stroke {
		stroke: #949494;
	}
}



.select-wrapper-lang {
	position: relative;
	display: inline-block;
	vertical-align: top;
}
.select-wrapper-lang .language {
	width: 20px;
	height: 15px;
	line-height: 14px;
	text-align: center;
	position: absolute;
	top:16px;
	left: 0;

	img {
		width: 20px;
		height: 15px;
		border: 1px solid #fff;
		border-radius: 2px;
		vertical-align: top;
	}
}

.select-wrapper-lang span {
	display: block;
	color: #fff;
	padding: 15px 0 15px 40px;
	text-decoration: none;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	z-index: 0;
	cursor: pointer;
	width: 100%;
	display: block;
}
.select-wrapper-lang select {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	height: 40px;
	z-index: 1;
	width: 100%;
	line-height: 40px;
	cursor: pointer;
	border: 1px solid transparent;
}

.select-wrapper {
	position: relative;
	display: block;
	margin-bottom: 20px;
}

.select-wrapper span {
	display: block;
	background: #ffffff;
	color: #2b2323;
	padding: 12px 20px;
	font-size: 12px;
	text-decoration: none;
	border: 1px solid #e2e2e2;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	z-index: 0;
	cursor: pointer;
	width: 100%;
	display: block;
}

.select-wrapper .arrow {
	position: absolute;
	top: 18px;
	right: 15px;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #c2c2c5;
}

.select-wrapper select {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	height: 40px;
	z-index: 1;
	width: 100%;
	line-height: 40px;
	cursor: pointer;
	border: 1px solid transparent;
}


.panel-options {
	border-top: 1px solid #353030;
	padding-top: 20px;
	color: #fff;
	border-bottom: 1px solid #353030;
}

.panel-option__line {
	padding: 0 20px;
	margin-bottom: 20px;
	@include make-row();

	.custom-checkbox__middle {
		margin-top: -5px;
	}

	.range-primary {
		margin-top: 10px;
	}

	.label {
		font-size: 14px;
	}
}


.panel-option__group {
	margin-bottom: 20px;

	.panel-option__line {
		margin-bottom: 0;
	}
}

.b-range {
	position: relative;
	padding-left: 120px;

	.label {
		position: absolute;
		top: 0;
		left: 10px;
		width: 120px;
	}
}



.custom-checkbox {
	width: 70px;
	height: 30px;
	border-radius: 30px;
	background: #5d5f5e;
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	position: relative;


	&:before {
		content:"";
		display: block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: absolute;
		top: 3px;
		left: 3px;
		background: #fff;
		box-shadow: 0 0 2px rgba(0,0,0,0.2);
	}

	label {
		display: block;
		height: 30px;
		width: 100%;
		color: #fff;
		line-height: 30px;
		text-transform: uppercase;
		font-size: 14px;
		padding-left: 30px;
		padding-right: 5px;
		text-align: center;
		cursor: pointer;
	}

	input {
		position: absolute;
		top: 0;
		left: -9999px;
		visibility: hidden;
	}

	&.active {
		background: #17b02a;

		label {
			padding-right: 30px;
			padding-left: 5px;
		}

		&:before {
			left: auto;
			right: 3px;
		}
	}
}

.custom-checkbox-light {
	background: #fff;
	border:1px solid #707271;

	&:before {
		top: 2px;
		border:1px solid #707271;
	}

	label {
		color: #707271;
		line-height: 29px;
	}

	&.active {
		background: #fff;
		border:1px solid #00b937;

		&:before {
			border:1px solid #00b937;
		}

		label {
			color: #00b937;
		}
	}
}


.b-info-range {

}


.b-info-range__distance {
	font-size: 14px;
}

.b-info-range__city {
	display: block;
	font-size: 11px;
	color: #959595;
}
