.block-reviews__link {
	display: inline-block;
	vertical-align: middle;
	font-weight: bold;

	.icon {
		margin-left: 3px;
	}
}

.block-reviews__add {
	padding: 10px;
	border-bottom: 1px solid #e2e2e2;
}

.block-review__list {

}

.block-review__item {
	padding: 10px;
	border-bottom: 1px solid #d8d8d8;

	.review-star {
		margin-bottom: 10px;
	}
}

.block-review__item {
	position:relative;

	.block-review__user-name {
		padding-right:50px;
	}

	.item__control {
		display:block;
		width:100%;
		text-align:right;
		position:relative;
		margin-top:-15px;
	}
	.item__control-answer {
		display:inline-block;
		font-size:9px;
		line-height:11px;
		color:#8D0000;

		&:hover {
			color:#000;
		}

		span {
			display:none;
		}
		.positive {
			display:block;
		}

		&.active {
			.positive {
				display:none;
			}
			.negative {
				display:block;
			}
		}
	}

	.answer {
		display:block;
		position:relative;
		padding-left:50px;
		padding-top:10px;
	}
	.answer__profile {
		display:block;
		position:relative;
		width:100%;
		margin-top:10px;
		padding-left:20px;
	}
	.answer__avatar {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:13px;
		height:13px;

		img {
			display:block;
			width:100%;
		}
	}
	.answer__body {
		display:block;
		width:100%;
		position: relative;
		padding-top:10px;

		&:before {
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:-10px;
			height:1px;
			background:#D8D8D8;
		}
	}
	.answer__name {
		display:block;
		padding-right:60px;
		padding-top:1px;
		font-size: 12px;
		line-height:13px;
		color: #27242b;
		margin: 0 0 5px 0;
		font-weight:bold;
	}
	.answer__text {
		font-size: 12px;
		line-height: 15px;
		color: #858585;
		p {
			margin: 5px 0 0 0;
			font-size: 11px;

			&:last-child {
				margin-bottom:0;
			}
		}
	}
	.answer__controls {
		display:block;
		position:absolute;
		top:0;
		right:0;
		cursor:default;

		.control {
			display:inline-block;
			width:13px;
			height:13px;
			padding:0;
			margin:0 0 0 8px;
			border:none;
			background:none;

			svg {
				display:block;
				width:13px;
				height:13px;
				fill:#626262
			}
			&:hover {
				svg {
					fill:#000;
				}
			}
		}
	}
	.anwser-form__wrap {
		display:none;
		width:100%;
	}
	.anwser-form__inner {
		display:block;
		width:100%;
		padding-left:50px;
		padding-top:10px;
	}
	.anwser-form {
		display:block;
		width:100%;
		position: relative;
		padding-top:10px;
		padding-bottom:10px;

		&:before {
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:-10px;
			height:1px;
			background:#D8D8D8;
		}
	}
	.anwser-form__title {
		display:block;
		font-size:9px;
		line-height:12px;
		color:#000;
		margin:0 0 10px;
		font-weight:bold;
	}
	.anwser-form__label {
		display:block;
		width:100%;
		position:relative;
		margin:0 0 10px;
	}
	textarea.anwser-form__input {
		display:block;
		width:100%;
		min-height:65px;
		max-height:300px;
		height: 65px;
		border: 1px solid #979797;
		border-radius: 2px;
		background: #f8f8f8;
		-webkit-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.06);
		box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.06);
		padding:8px 10px;
		resize: vertical;
		font-size:10px;
		line-height:12px;
	}
	.btn__wrap {
		text-align:right;

		.anwser-form__submit {
			font-size:10px;
			padding:8px 12px;
			border-radius:2px;
		}
	}
	.review__gallery {
		display:block;
		margin:0;
		padding:1px 0 5px;
		cursor:default;
		font-size:0;
		line-height:0;

		li {
			display:inline-block;
			margin-right:10px;
			position:relative;
			margin-top:5px;
			padding-bottom:5px;

			&:last-child {
				margin-right:0;
			}
		}
		.review__img {
			display:block;
			width:60px;
			height:60px;
			background-position:50% 50%;
			background-repeat:no-repeat;
			-webkit-background-size:cover;
			background-size:cover;
			border-radius:4px;
			position:relative;
		}
		.remove-img {
			display:block;
			position:absolute;
			width:18px;
			height:18px;
			top:-2px;
			right:-2px;
			background:none;
			border:none;
			margin:0;
			opacity:.8;
			background:rgba(0,0,0,0.8);
			border-radius:50%;
			padding:3px;

			svg {
				display:block;
				width:12px;
				height:12px;
				fill:#fff;
			}

			&:hover {
				opacity:1;
			}
		}
	}
}//items_review

.btn-fast-review {
	display:block;
	position:fixed;
	bottom:10px;
	right:10px;
	width:50px;
	height:50px;
	background:#16AF2A;
	border-radius:50%;
	box-shadow: 0 4px 4px rgba(0,0,0,.2);
	z-index:300;
	border:none;
	padding:14px 10px 10px 10px;
	transition:opacity .3s ease, visibility .3s ease;
	opacity:0;
	visibility:hidden;

	svg {
		display:block;
		width:100%;
		height:100%;
		fill:#fff;
	}
	&.showing {
		opacity:1;
		visibility:visible;
	}
}

.block-review__description {
	color: #979696;
	margin: 0 0 10px 0;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}
}


.block-review__user {

}

.block-review__user-avatar {
	float: left;
	max-width: 15px;
	margin-right: 7px;
	margin-top: -1px;

	img {
		vertical-align: top;
		max-width: 100%;
		height: auto;
		border:1px solid #e3e3e3;
	}
}

.block-review__user-name {
	color: #231f27;
	font-weight: bold;
	overflow: hidden;
}


.add-review {
	display:block;
	padding: 10px;
}


.add-review__headline {
	text-align: center;
	color: #878585;
	margin-bottom: 10px;
}


.br-theme-css-stars .br-widget {
	height: 30px;
	text-align: center;
	font-size: 0;
	margin-bottom: 20px;
}
.br-theme-css-stars .br-widget a {
	text-decoration: none;
	height: 30px;
	width: 30px;
	display: inline-block;
	vertical-align: top;
	font-size: 23px;
	margin: 0 5px;
	background: url(../images/svg/icon-star.svg) 50% 50% no-repeat;
	background-size: contain;
}

.br-theme-css-stars .br-widget a.br-active,
.br-theme-css-stars .br-widget a.br-selected {
	background: url(../images/svg/icon-star-fill.svg) 50% 50% no-repeat;
}
.br-theme-css-stars .br-widget .br-current-rating {
	display: none;
}
