.infobox-wrapper {
	display: none;
}

#infobox {
	background: #ffffff;
	padding: 20px 10px;
	box-shadow: 0 6px 6px rgba(0,0,0,0.5);
	position: relative;

	&:before {
		content:"";
		display: block;
		position: absolute;
		bottom: -10px;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -10px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #fff;
	}
}


.infobox__club {
	overflow: hidden;
	display: block;
	text-align: left;

	.review {
		margin: 0;
	}
}

.infobox__club-img {
	max-width: 70px;
	float: left;
	padding-right: 15px;

	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
		border: 1px solid #e2e2e2;
	}
}

.infobox__club-description {
	overflow: hidden;
}


.infobox__club-name {
	display: block;
	font-weight: bold;
	font-size: 14px;
	color: #1b171d;
	margin-bottom: 3px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.infobox__info {
	font-size: 11px;
	color: #747272;
	margin-bottom: 3px;
	height: 25px;
	overflow: hidden;

	p {
		margin: 0;
	}
}


.gm-style {
	font-family: $font-default;
}
