.group-lines {
	font-size: 11px;
}

.group-lines__item {
	border-bottom: 1px solid #e2e2e2;
	padding: 10px 5px;
	display: table;
	width: 100%;

	.column {
		display: table-cell;
		vertical-align: top;
		padding: 0 5px;
	}

	.custom-checkbox {
		&.right {
			margin-left: 20px;
			margin-bottom: 5px;
		}
	}

	.column-small {
		width: 55px;
	}

	.review {
		margin: 0;
	}

	.block-img {
		svg,
		img {
			max-width: 100%;
			height: auto;
			display: block;
			border:1px solid #e2e2e2;
		}
	}

	.flag-icon {
		border:1px solid #e2e2e2;
		display: block;
	}

	.label {
		font-weight: bold;
		color: #231f27;
	}

	.label-date {
		display: block;
		color: #000000;
		font-size: 11px;
		line-height: 1.2;
		font-weight: bold;
		margin-bottom: 5px;
	}

	.h2 {
		display: block;
		font-size: 14px;
		color: #231f27;
		margin: 0 0 5px 0;
		font-weight: bold;
	}

	.description {
		color: #878585;

		p {
			margin: 0 0 5px 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		mark {
			background-color: transparent;
			font-size: 11px;
			color: #bfbfbf;
			display: block;
			margin-bottom: 5px;
		}

		time {
			display: block;
			margin-bottom: 5px;
		}

		.flag-icon {
			display: inline-block;
			vertical-align: top;
			width: 20px;
			height: 15px;
			margin: -1px 3px 0;
		}
	}
}

.panel-primary {
	color: #231f27;
	overflow: hidden;
	font-size: 11px;
	padding: 2px 0;
	margin-top: 5px;

	.icon {
		fill:#BFBFBF;
		width: 15px;
		height: 15px;
		overflow: hidden;
		float: left;
		margin: -2px 5px 0 0;
	}

	.text-color {
		overflow: hidden;
		color: #b4b4b4;
		line-height: 1;
	}
}

.group-lines__item-dropdown {
	.column-arrow {
		width: 70px;
		font-weight: bold;
		color: #231f27;
		text-align: right;
		padding-right: 25px;
	}

	.description-dropdown {
		color: #878585;
	}

	&.open {
		.description-dropdown {
			display: block;
		}

		.icon-dropdown {
			@include rotate(180deg);
		}
	}
}

.review {
	margin: 10px 0;
}

.review__label {
	font-size: 10px;
	display: inline-block;
	vertical-align: middle;
	color: #aeaeae;
}

.review-star {
	font-size: 0;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;

	.icon {
		width: 10px;
		height: 10px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		margin-right: 1px;
		fill:#787878;
	}
}

.group-lines-primary {
	color: #000;

	.label-date {
		margin-bottom: 0;
	}

	.group-lines__item {
		padding: 15px 10px;
	}
}


.no-results {
	padding: 10px;
	position: relative;
}

.no-results_search {
	padding-top: 20px;
	padding-bottom: 50px;

	.banner {
		margin: 35px 0 0 0;
	}
}

.no-results__headline {
	text-align: center;
	display: block;
	font-size: 15px;
	line-height: 15px;
	margin-bottom: 15px;
}

.banner {
	text-align: center;
	margin: 20px 0;

	iframe,
	img {
		vertical-align: top;
		max-width: 100%;
		height: auto;
		border: none;
	}
}


.b-search_color {
	.form-control {
		border: 1px solid #d2d2d2;
	}

	.btn-search {
		border-radius: 5px;
		background: url(../images/svg/icon-search-white.svg) 50% 50% no-repeat #90191c;
		background-size: 20px 20px;
	}
}

.b-search_medium {
	.form-control {
		height: 30px;
	}

	.btn-search {
		height: 30px;
		width: 30px;
		background-size: 15px 15px;
		border-radius: 5px;
	}
}


.b-message {
	position: absolute;
	z-index: 11;
	top: 0;
	left: 0;
	width: 100%;
}

.b-message__item {
	position: relative;
	padding: 17px 35px;
	font-weight: bold;
	font-size: 11px;

	p {
		margin: 0;
	}
}

.b-message__item_danger {
	background-color: rgba(255,227,227,.95);
	color: #aa0004;
	font-size: 11px;

	.b-message__item__icon {
		fill:#aa0004;
	}
}

.b-message__item_success {
	background-color: rgba(244,255,217,.95);
	color: #367906;

	.icon {
		fill:#367906;
	}
}

.b-message__item_warning {
	background-color: rgba(255,253,189,.95);
	color: #9a7801;

	.icon {
		fill:#9a7801;
	}
}

.b-message__item__close {
	position: absolute;
	top: 15px;
	right: 10px;

	.icon {
		fill:#000;
		opacity: .5;
		display: block;
		width: 15px;
		height: 15px;
	}
}

.b-message__item__icon {
	position: absolute;
	top: 15px;
	left: 10px;

	svg {
		display: block;
		width: 16px;
		height: 16px;
	}
}
