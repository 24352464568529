.user-panel {
	padding: 20px 10px;
	overflow: hidden;
	border-bottom: 1px solid #d8d8d8;
}

.user-panel__avatar {
	border:1px solid #f3f3f3;
	width: 100px;
	position: relative;
	float: left;
	margin-right: 10px;

	img {
		width: 100%;
		height: auto;
		display: block;
	}
}

.avatar-edit {
	width: 35px;
	height: 35px;
	position: absolute;
	overflow: hidden;
	display: block;
	bottom: 0;
	right: 0;
	text-align: center;

	&:before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-top: 35px solid transparent;
		border-bottom: 35px solid transparent;
		border-right: 35px solid rgba(255,255,255,0.6);
	}

	.icon {
		fill: #900000;
		width: 17px;
		height: 17px;
		display: block;
		position: absolute;
		top: 15px;
		left: 15px;

	}
}

.user-panel__info {
	overflow: hidden;
	font-size: 15px;
	line-height: 1.1;
	color: #878585;

	h2 {
		margin: 0 0 10px 0;
		font-size: 17px;
		color: #231f27;
	}

	p {
		margin: 10px 0 0 0;
		line-height: 1.4;
	}
}



.menu-primary {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 14px;
	font-weight: bold;
	color: #231f27;

	li {
		border-bottom: 1px solid #d8d8d8;

		&:last-child {
			border-bottom: none;
		}
	}

	a {
		color: #231f27;
		display: block;
		padding: 10px 30px 10px 10px;
		position: relative;
		overflow: hidden;
	}

	.icon {
		fill:#828282;
	}

	.icon-left {
		stroke:#828282;
		width: 20px;
		height: 20px;
		float: left;
		margin: -2px 10px -2px 0;
	}

	.icon-arrow-right {
		width: 7px;
		height: 10px;
		position: absolute;
		top: 14px;
		right: 10px;
		display: block;
	}
}



