.layout-footer {
	@extend .container-fluid;
	padding-top: 15px;
	padding-bottom: 15px;

	.headline {
		display: block;
		color: $footer-color-headline;
		margin-bottom: 15px;
		font-size: 18px;
	}

	a {
		color: $footer-color-link;
	}

	.links {
		padding: 0;
		margin: 0 0 15px 0;
		list-style: none;
		font-size: 11px;

		li {
			margin: 5px 0;
		}
	}

}

.social-accounts {
	padding: 0;
	margin: 0 0 10px 0;
	list-style: none;
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 10px 10px 0;
	}

	.icon {
		display: block;
		width: 20px;
		height: 20px;
		overflow: hidden;
	}
}


.copyright {
	margin: 0;
	font-size: 11px;
	color: $footer-color-link;
}
