.favourites-clubs {

}

.favourites-club__item {
	border-bottom: 1px solid #d8d8d8;

	&.open {
		border-bottom: 1px solid transparent;
		.favourites-club__sub {
			display: block;
		}

		.icon-dropdown {
			@include rotate(180deg);
		}
	}
}


.favourites-club__sub {
	display: none;

	.favourites-clubs__sub-line{
		&:last-child {
			border-bottom: none;
		}
	}
}

.favourites-club__sub-line {
	padding: 10px;
	border-bottom: 1px solid #d8d8d8;
	color: #918f8f;
	font-size: 11px;
	overflow: hidden;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}

	.custom-checkbox {
		float: right;
		margin-left: 20px;
	}

	.description {
		overflow: hidden;
		min-height: 30px;
		line-height: 30px;
	}

	.middle {
		line-height: 1.1;
		display: inline-block;
		vertical-align: middle;
	}
}


.favourites-club__info {
	overflow: hidden;
	white-space: nowrap;
	font-size: 14px;
	color: #231f27;
	font-weight: bold;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
}


.favourites-club__img {
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	padding-right: 10px;

	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
		border:1px solid #e4e4e4;
	}
}

.favourites-club__name {
	overflow: hidden;
}


.favourites-club__link {
	padding: 10px 35px;
	position: relative;

	.icon {
		width: 10px;
		height: 10px;
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -5px;
		fill:#d2cad5;
	}

	.icon-remove {
		left: 10px;
	}

	.icon-dropdown {
		right: 10px;
	}
}
