.layout-header {
	background: #fff;
	border-bottom: 1px solid #dbdbdb;
	height: 50px;
	padding-left: 10px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;

	.navigation {
		float: right;
	}

	.logo {
		display: block;
		width: 78px;
		height: 34px;
		background: url(../images/svg/pokerdiscover.svg) no-repeat;
		background-size: 78px 34px;
		float: left;
		margin-top: 8px;
	}
}
