@mixin flag-icon-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
}

.flag-icon {
	@include flag-icon-background();
	position: relative;
	display: inline-block;
	line-height: 1;

	&:before {
		content: '\00a0';
	}
}

.flag-container {
	width: 100%;
	display: inline-block;
	position: relative;
	overflow: hidden;

	&:after {
		padding-top: 75%;
		display: block;
		content: '';
	}

	.flag-icon {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	&.flag-small {
		width: 30px;
		height: 20px;
	}

	&.flag-medium {
		width: 40px;
		height: 30px;
	}
}

@mixin flag-icon($country) {
	.flag-icon-#{$country} {
		background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
		&.flag-icon-squared {
			background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg);
		}
	}
}
