.message-list {
	position: fixed;
	top: 50px;
	left: 0;
	width: 100%;
	z-index: 10;
	display: none;
}

.message-line {
	background: #ffffff;
	overflow: hidden;
	border-bottom: 1px solid #d8d8d8;
	padding: 16px 10px;
	color: #90191c;
	font-size: 11px;

	.icon {
		float: left;
		width: 15px;
		height: 15px;
		overflow: hidden;
		margin-right: 5px;
		margin-top: -2px;
	}
}

.message__info {
	overflow: hidden;
	font-weight: bold;
	font-size: 11px;
}

.message-danger {
	color:#90191c;

	.icon {
		fill:#90191c;
	}
}

.message-success {
	background-color: #ecfaf0;
	color:#00b837;
	.icon {
		fill:#00b837;
	}
}

