.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.tab-nav-default {
	@include make-row();
	padding: 0 10px;
	margin: 0;
	list-style: none;
	text-align: center;
	font-size: 11px;
	border-bottom: 1px solid #e3dee8;

	a {
		color: #fff;
		display: block;
		height: 35px;
		line-height: 35px;
		background-color: #8d0000;
		border-top: 1px solid #e3dee8;
		border-left: 1px solid #e3dee8;

	}

	li {
		position: relative;
		margin-bottom: -1px;
		border-right: 1px solid #e3dee8;



	}

	.active {
		a {
			color: #27242b;
			background-color: #fff;

		}
	}
}


.tab-nav-full {
	padding: 0;

	a {
		border-left: 0;
	}
}


.wrapper-tabs {
	overflow: hidden;
	.tab-nav-default  {
		margin-right: -1px;
	}
	&.no-border {
		.tab-nav-default {
			a {
				border-top: none;
			}
		}
	}
}
