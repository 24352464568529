.btn-wrap {
	display:block;
	width:100%;
	margin-bottom:15px;

	&.btn-wrap__margin-top {
		margin-top:40px;
	}

	&:last-child {
		margin-bottom:0;
	}
}

.text-center {
	text-align:center;
}
.text-left {
	text-align:left;
}
.text-right {
	text-align:right;
}

.btn-danger {
	display: inline-block;
	vertical-align: top;
	background-color: #7b0000;
	text-align: center;
	padding: 12px 15px;
	color: #fff;
	font-size: 14px;

	&:hover {
		color:#fff;
		background-color: #8d0000;
	}

	span.arrow {
		position: relative;
		padding-right: 15px;

		&:before {
			content:"";
			position: absolute;
			display: block;
			top: 7px;
			right: 0;
			width: 0;
			height: 0;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 4px solid #fff;
		}
	}

	&.open {
		span.arrow {
			&:before {
				top: 3px;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid transparent;
				border-bottom: 4px solid #fff;
			}
		}
	}
}

.btn-wide {
	width: 100%;
}
.btn-lg {
	font-size:18px;
	padding: 14px 17px;
	min-width:160px;
	text-align:center;
	background-color: #900000;
	color: #fff;
}
button {
	border:none;
	cursor:pointer;
}
