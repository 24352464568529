.dropzone, .dropzone * {
  box-sizing: border-box;
}
.dropzone {

  position: relative;

  .dz-preview {
    position: relative;
    display: inline-block;
    width: 120px;
    margin: 0.5em;

    .dz-progress {
      display: block;
      height: 15px;
      border: 1px solid #aaa;
      .dz-upload {
        display: block;
        height: 100%;
        width: 0;
        background: green;
      }
    }

    .dz-error-message {
      color: red;
      display: none;
    }
    &.dz-error {
      .dz-error-message, .dz-error-mark {
        display: block;
      }
    }
    &.dz-success {
      .dz-success-mark {
        display: block;
      }
    }

    .dz-error-mark, .dz-success-mark {
      position: absolute;
      display: none;
      left: 30px;
      top: 30px;
      width: 54px;
      height: 58px;
      left: 50%;
      margin-left: -(54px/2);
    }


  }

}
//customs
#template-preview {
  display:none;
  visibility:hidden;
}
.dropzone {
  display:block;
  width:100%;
  position:relative;
  padding-bottom:60px;

  //.dz-details {
  //	display:none;
  //}
  .dz-preview {
    display:inline-block;
    width:60px;
    height:60px;
    position:relative;
    margin:10px 10px 0 0;


    &:hover {
      .dz-image {
        &:before {
          display:block;
        }
      }
      .dz-remove {
        opacity:1;
      }
    }
  }
  .dz-image {
    display:block;
    width:60px;
    height:60px;
    overflow:hidden;
    border-radius:4px;
    position:relative;
    background:#9c9c9c;

    &:before {
      content:'';
      display:none;
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background:#000;
      opacity:.5;
      border-radius:4px;
    }
  }
  .dz-remove {
    display:block;
    position:absolute;
    width:18px;
    height:18px;
    top:-2px;
    right:-2px;
    background:none;
    border:none;
    margin:0;
    opacity:.8;
    background:rgba(0,0,0,0.8);
    border-radius:50%;
    padding:3px;

    svg {
      display:block;
      width:12px;
      height:12px;
      fill:#fff;
    }
  }
  .dz-preview {
    &.dz-error {
      .dz-progress {
        .dz-upload {
          max-width:90%;
        }
      }
    }
    &.dz-success {
      .dz-progress {
        display:none;
      }
    }
    .dz-progress {
      display:block;
      position:absolute;
      left:5px;
      right:5px;
      height:10px;
      top:50%;
      margin-top:-5px;
      border:1px solid #870000;
      background:#fff;
      border-radius:5px;

      .dz-upload {
        background:#870000;
        border-radius:5px;
      }
    }
  }
  .dz-preview {
    .dz-error-mark {
      display:block;
      position:absolute;
      top:50%;
      text-align:center;
      left:5px;
      right:5px;
      font-size:10px;
      line-height:10px;
      color:#fff;
      margin:-5px 0 0 ;
      height:10px;
      width:auto;
    }
  }

  .dz-message {
    display:block;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:50px;
    background:#fff;
    border:3px dashed #EDEDED;
    border-radius:4px;

    span {
      display:block;
      position:absolute;
      top:50%;
      left:10px;
      right:10px;
      transform:translate3d(0,-50%,0);
      font-size:14px;
      line-height:16px;
      font-weight:normal;
      color:#858585;
      text-align:center;
      cursor:default;

      b {
        cursor:pointer;
        font-weight:normal;
        color:#8D0000;
      }
    }
  }
}// dropzone customs
