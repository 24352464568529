.padding_top {
	padding-top:30px;
	
	img {
		display:block;
		max-width:100%;
	}
	
}
.prmg-block.prmg-block__bg-image {
	padding-top: 20px;
	padding-bottom: 40px;
}
.prmg-block {
	display:block;
	width:100%;
	padding-top: 55px;
	padding-bottom: 55px;

	.row__has-slider {
		padding-bottom:0;
		p {
			text-align: center;
			font-size: 24px;
			line-height: 34px;
			margin: 0;
		}
	}
	
	.row {
		
		p {
			text-align: center;
			font-size: 12px;
			line-height: 17px;
			margin: 0;
		}
		
		p.fz-inc {
			font-size: 9px;
			line-height: 13px;
			margin-bottom: 8px;
		}
		
	}

	&.prmg-block__blue {
		background-color:#f2f5fa;
	}
	
	&.prmg-block__border-bottom {
		border-bottom:1px solid #ebebeb;
	}
	
	&.prmg-block__padding-top {
		padding-top:160px;
	}
	
	.container {
		.row {
			margin: 0;
		}
		
		.row.row__has-slider {
			width: 95%;
			margin: 0 auto;
		}
		
		.btn-wrap {
			margin-top: 25px;
			
			.btn-lg {
				font-size: 14px;
				line-height: 18px;
				padding: 10px 30px;
				min-width: 0;
			}
			
		}
	}
	
	.prmg-block__title {
		display:block;
		font-size: 20px;
		line-height: 25px;
		font-weight: 700;
		text-transform: uppercase;
		color:$black-color;
		margin:0;
		margin-bottom: 20px;
		
		&.prmg-block__title-red {
			color:$color-main;
		}
		
	}
	
	.prmg-block__title-slider {
		margin-bottom: 42px;
	}
	
	.prmg-block__subtitle {
		display:block;
		color: $color-subtext;
		font-size: 12px;
		line-height: 17px;
		font-weight: 400;
		text-transform: uppercase;
		margin:0 0 12px;
	}
	
	.prmg-block__minititle {
		display:block;
		color: $color-subtext;
		font-size: 15px;
		line-height: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin:0 0 40px;
		text-align: center;
		
		&.fz-inc {
			font-size:23px;
		}
		
		&.prmg-block__minititle-red {
			color:$color-main;
		}
		
		&.pt-lg {
			padding-top: 0px;
			margin-bottom: 15px;
		}
		
	}
	
	p {
		color:$color-text;
		font-size: 15px;
		margin-top:0;
		font-weight: 400;
		line-height: 26px;
		
		&.fz-inc {
			font-size:18px;
			line-height: 25px;
		}
		
		&.fz-inc.text-left {
			text-align: left;
		}
		
	}
	
	.cards-img__wrap {
		padding-top: 12px;
		display:block;
		margin-bottom: 20px;
		
		img {
			display: block;
			max-width: 85%;
			height: auto;
			margin: 0 auto;
		}
		
	}
	
	.review-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		flex-direction: row;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		-webkit-box-pack: left;
		-webkit-justify-content: left;
		-ms-flex-pack: left;
		justify-content: left;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width:100%;
		margin:0;
		padding:0;
		
		li {
			display: block;
			list-style:none;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			padding-top:0;
			padding-bottom:0;
		}
		
		.slick-slide {
			margin: 0 32px;
		}
		
		.review-one {
			display:block;
			height:100%;
			width:100%;
			max-width: 600px;
			margin: 0 auto;
			padding: 15px 13px;
			position:relative;
			background:$white-color;
			box-shadow: 0 3px 4.8px 0.3px rgba(0, 0, 0, 0.05);
			white-space:nowrap;
			text-align:center;
		}
		
		.review-one__text {
			display:inline-block;
			vertical-align:middle;
			white-space:normal;
			margin:0;
			font-style: italic;
			color: #5e5d5d;
			font-size: 9px;
			line-height: 15px;
			margin-bottom: 5px;
			
			&:before {
				content:'';
				display:block;
				margin:5px auto 10px;
				width: 13px;
				height: 8px;
				background:url(../images/svg/quotes.svg) 50% no-repeat;
				background-size: 13px 8px;
			}
			
		}
		
		.review-one__author {
			display:block;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			padding: 6px;
			padding-bottom: 0;
		}
		
		.review-one__author-avatar {
			display:block;
			width: 50px;
			height: 50px;
			background-color: #ccc;
			background-repeat:no-repeat;
			background-position:50%;
			background-size:cover;
			border-radius:50%;
			margin:0 auto 20px;
			margin-bottom: 10px;
		}
		
		.review-one__author-name {
			display:block;
			color: $color-subtext;
			font-size: 12px;
			line-height: 15px;
			font-weight: 700;
			text-align:center;
			margin-bottom:5px;
		}
		
		.review-one__author-post {
			display:block;
			font-size: 9px;
			line-height: 12px;
			font-weight: 400;
			color: $color-subtext;
			text-align:center;
		}
	}//review-list
	
	
	
	.slick-wrapper {
		position: relative;
		
		.slick-controls.slick-has-controls-direction {
			width: 100%;
			display:block;
			position:absolute;
			top: 50%;
			margin-top: -15px;
			left: 0;
			
			.bx-prev,
			.bx-next {
				z-index: 100;
				position: absolute;
				display: block;
				width: 31px;
				height: 31px;
				border: 1px solid #d1d4d9;
				border-radius: 50%;
				transition: opacity .2s ease;
				
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 8px;
					height: 8px;
					margin-top: -4px;
					transform: rotate(45deg);
				}
				
			}
			
			.bx-next.slick-disabled,
			.bx-prev.slick-disabled {
				opacity: 0;
			}
			
			.bx-prev {
				left: 28px;
				&:after {
					margin-left: -2px;
					border-left: 1px solid #d1d4d9;
					border-bottom: 1px solid #d1d4d9;
				}
			}
			
			.bx-next {
				right: 28px;
				&:after {
					margin-left: -6px;
					border-right: 1px solid #d1d4d9;
					border-top: 1px solid #d1d4d9;
				}
			}
			
		}
	}
	.prmg-block__slider-wrap {
		display:block;
		overflow:hidden;
		position:relative;
		
		.bx-controls.bx-has-pager {
			display:block;
			position:absolute;
			bottom:0px;
			left:0;
			right:0;
		}
		
		.bx-pager {
			display:block;
			text-align:center;
			font-size:0;
			line-height:0;
		}
		
		.bx-pager-item {
			display:inline-block;
			vertical-align:top;
			margin:0 4px;
		}
		
		.bx-pager-link {
			display:block;
			width: 7px;
			height: 7px;
			border: 1px solid $grey-color;
			border-radius:50%;
			font-size:0;
			line-height:0;
			margin:0;
			padding:0;
			background:transparent;
			
			&.active {
				border-color:$color-main;
				background:$color-main;
				cursor:default;
			}
			
		}//bx-pager-link
		
		&.prmg-block__slider-wrap-full {
			display:block;
			position:relative;
			margin-bottom: 30px;
			
			&:before {
				content:'';
				display:block;
				position:absolute;
				top:0;
				left:0;
				bottom:0;
				width:25px;
				z-index:1;
				background: #ffffff;
				background: -moz-linear-gradient(left,  #ffffff 0%, rgba(255,255,255,0) 100%);
				background: -webkit-linear-gradient(left,  #ffffff 0%,rgba(255,255,255,0) 100%);
				background: linear-gradient(to right,  #ffffff 0%,rgba(255,255,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='rgba(255,255,255,0)',GradientType=1 );
			}
			
			&:after {
				content:'';
				display:block;
				position:absolute;
				top:0;
				right:0;
				bottom:0;
				width:25px;
				background: #fff;
				background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, #ffffff 100%);
				background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,#ffffff 100%);
				background: linear-gradient(to right,  rgba(255,255,255,0) 0%,#ffffff 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(255,255,255,0)', endColorstr='#ffffff',GradientType=1 );
			}
		}

		&.prmg-block__slider-wrap-mini {
			-webkit-transform:translate(-7px,0);
			transform:translate(-7px,0);
			
			.bx-wrapper {
				position:absolute;
				left: 96px;
				top: 23px;
				height:440px;
			}
			
		}
		
		.img-on-bg {
			display:block;
		}
		
	}//prmg-block__slider-wrap

	.slider-full {
		display:block;
		
		.slide-one {
			display:block;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			padding-left:15px;
			padding-right:15px;
			overflow:hidden;
			border-radius:4px;
			
			img {
				display:block;
				max-width:100%;
				height: auto;
				margin: 0 auto;
			}
			
		}
	}
	
	.slider-mini {
		display:block;
		margin:0;

		.slide-one {
			display:block;

			img {
				display:block;
				max-width: 100%;
				height: auto;
			}
			
		}
	}
}//prmg-block

.prmg-block__bg-image {
	background-image: url(../../assets/images/pattern.png);
	background-repeat: repeat;
}

.prmg-block__review {
	padding-top: 25px;
	padding-bottom: 35px;
	
	.container {
		width: 100%;
		padding: 0;
		margin: 0;
		
		.row {
			margin: 0;
			width: 100%;
		}
		
	}
}

.prmg-block__form {
	padding-bottom: 30px;
	
	.prmg-block__title {
		margin-bottom: 10px;
	}
	
	.prmg-block__minititle.fz-inc {
		font-size: 15px;
		line-height: 21px;
		margin-bottom: 17px;
	}
	
	p.fz-inc {
		font-size: 12px;
		line-height: 17px;
		margin-bottom: 20px;
	}
	
	.form__srn {
		.form-container {
			
			.form-line {
				margin-bottom: 10px;
			}
			
			.form-line-submit {
				margin-top: 30px;
				margin-bottom: 0;
				
				.btn {
					padding-left: 35px;
					padding-right: 35px;
				}
				
			}
		}
	}
	
}

@media (min-width: 480px) {
	.promo-managers {
		.container {
			width: 100%;
		}
	}
}




