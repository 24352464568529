.section-club__header {
	padding-bottom: 10px;
	position: relative;

	.call {
		position: absolute;
		bottom: 0;
		right: 10px;
	}
}


.section-club__img {
	text-align: center;
	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}
}

.section-club__gallery {
	overflow: hidden;
}

.call {
	width: 50px;
	height: 50px;
	display: block;
	border-radius: 50%;
	-webkit-box-shadow: 0 4px 4px rgba(0,0,0,.2);
	-moz-box-shadow: 0 4px 4px rgba(0,0,0,.2);
	box-shadow: 0 4px 4px rgba(0,0,0,.2);

	.icon {
		width: 50px;
		height: 50px;
	}
}


.section-club__logo {
	position: absolute;
	bottom: 0;
	left: 10px;
	max-width: 50px;

	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
		border: 1px solid #e2e2e2;
	}
}


.section-club__name {
	border-bottom: 1px solid #d8d8d8;
	color:#231f27;
	font-size: 17px;
	font-weight: bold;
	padding: 10px;
}


.section-club__description {
	padding: 10px;
	position: relative;
	border-bottom: 1px solid #d8d8d8;

	.icon-favorites {
		position: absolute;
		top: 0;
		right: 20px;
		cursor: pointer;
	}
}

.section-club__links {
	font-size: 11px;
	@extend .row;

	.icon {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.icon-map {
		margin-right: 5px;
	}

	.icon-globe {
		margin-left: 5px;
	}
}


.section-club {
	.wrapper-tabs {
		.tab-nav-default {

		}
	}

}

.section-club__info {
	padding-right: 50px;
	color: #878585;
	margin-bottom: 8px;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}

	.review {
		margin: 0 0 8px 0;
	}
}


.gallery-primary {
	padding: 0;
	margin: 0;

	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
		margin-left: auto;
		margin-right: auto;
	}
}

#spin-preloader {
	position: absolute;
	top:50%;
	left: 50%;
}

.preloader-min-height {
	min-height: 200px;
	overflow: hidden;
}

.preloader-hide {
	opacity: 0;
}
