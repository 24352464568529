.add-cash {

	.group-icons {
		float: right;
		font-size: 0;
		margin: -7px 0;

		.icon {
			display: inline-block;
			vertical-align: middle;
			width: 25px;
			height: 25px;
			margin-left: 15px;
		}
	}
}

.icon-play-primary {
	fill:#00B937;
}

.icon-refresh {
	fill:#707271;
}

.icon-pause {
	fill:#8D0000;
}

.add-cash__button {
	text-align: center;
	padding-bottom: 20px;
}

.add-cash__list {
	margin-bottom: 20px;
}

.cash__item {
	font-size: 11px;
	color: #878585;
	display: block;
	border-bottom: 1px solid #d8d8d8;
	padding: 15px 10px;
	@include clearfix;

	a {
		color: #878585;
	}
}
