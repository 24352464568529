.list-clubs {
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		font-size: 14px;
		font-weight: bold;
		border-bottom: 1px solid #d8d8d8;
	}

	a {
		color: #231f27;
		display: block;
		padding: 15px 10px;
	}

	i {
		color: #858484;
		font-style: normal;
		font-weight: normal;
	}
}
