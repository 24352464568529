.filters {
	background-color: #e8ebf0;
	padding-bottom: 20px;

	.filters__item:last-child {
		border-bottom: none;
	}
}

.filters__item {
	border-bottom: 1px solid #d8d8d8;
	padding: 15px 10px;
}


.detect-location {
	text-align: center;
	font-size: 11px;
	margin: 20px 0 10px 0;


	.icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
	}

	.icon-location {
		width: 20px;
		height: 20px;
		fill: #8d0000;
	}
}
