.section-poker-search {
	@extend .container-fluid;
	background:#000 url(../images/pages/home/bg-header.jpg) 50% 0 no-repeat;
	background-size: cover;
	color: #fff;

	.wrapper {
		padding: 30px 10px;
	}

	.b-search {
		margin-bottom: 60px;
	}

	.hgroup {
		font-weight: 700;
		text-align: right;
		text-transform: uppercase;
		margin-bottom: 60px;

		small,
		span {
			display: block;
		}

		span {
			font-size: 40px;
			line-height: 40px;
		}

		p {
			margin: 0;
			font-size: 30px;
			line-height: 30px;
		}

		small {
			font-size:18px;
			line-height: 25px;
		}
	}

}

.section-about {
	@extend .container-fluid;
	background-color: #f2f5fa;
	background-image: url(../images/bg-map.png);
	background-position: 50% 20px;
	background-repeat: no-repeat;
	background-size: 372px auto;
	text-align: center;
	font-size: 15px;
	line-height: 23px;
	font-weight: 700;

	p {
		margin: 0 0 10px 0;
	}

	mark {
		background: none;
		color: #900000;
	}

	.content {
		padding: 40px 10px 60px 10px;
	}
}

.section-video {
	text-align: center;
	background-color: #f2f5fa;
	font-size: 14px;
	line-height: 15px;
	font-weight: 700;

	.content {
		padding: 30px 0;
	}

	.icon {
		margin-bottom: 10px;
		display: inline-block;
		vertical-align: top;
	}

	p {
		margin: 0 0 20px 0;
	}
}

.section-poker {

	.tab-nav-default {
		margin-top: -35px;
	}

	.tab-content {
		@extend .container-fluid;
		padding-top: 35px;
	}

	.tab-pane {
		padding-top: 10px;
	}

	.section-video {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.steps {
	font-size: 13px;
	color: #858484;
	text-align: center;


	.headline {
		display: block;
		font-size: 22px;
		line-height: 22px;
		font-weight: 700;
		color: #000;
		margin-bottom: 40px;
	}

	p {
		margin: 0;
		line-height: 13px;
	}


	.icon {
		margin-bottom: 20px;
		width: 90px;
		height: auto;
		vertical-align: top;
	}

	.icon-1 {
		width: 60px;
	}

	.icon-2 {
		width: 90px;
	}

	.icon-3 {
		width: 80px;
	}

	.step {
		margin-bottom: 40px;

		span {
			display: block;
			font-size: 15px;
			line-height: 15px;
			font-weight: 700;
			margin: 0 0 10px 0;
			color: #000;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}


.b-search {
	.label {
		display: block;
		font-weight: 700;
		font-size: 30px;
		margin-bottom: 10px;
		text-align: right;
	}

	.form-control {
		width: 100%;
		height: 40px;
		border-radius: 5px;
		background: #fff;
		color: #000;
		border: none;
		padding-right: 40px;
		padding-left: 10px;
		font-size: 14px;
	}

	p {
		margin: 5px 0;
		font-size: 12px;
		line-height: 12px;
		color: #ffffff;
		text-align: right;
		padding-left: 10%;
	}

	.wrapper-search {
		position: relative;
		margin-bottom: 5px;
	}

	.wrapper-search-input {
		display: inline-block;
		width: -moz-calc(100% - 44px);
		width: -webkit-calc(100% - 44px);
		width: calc(100% - 44px);
		position: relative;
	}

	.clear-input {
		display: inline-block;
		position: absolute;
		width: 15px;
		height: 15px;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		margin-right: 7px;
	}

	.clear-input svg {
		display: none;
	}

	.close-icon {
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 2px;
			height: 18px;
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			background: #332b2b;
			margin: auto;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 2px;
			height: 18px;
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
			background: #332b2b;
			margin: auto;
		}
	}

	.geo-icon svg {
		display: block;
	}

	.btn-search {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 40px;
		border: none;
		cursor: pointer;
		display: block;
		background: url(../images/svg/icon-search.svg) 50% 50% no-repeat;
		background-size: 25px 25px;
		text-align: left;
		text-indent: -9999px;
	}
}

@media only screen and (orientation : landscape)  {
	.section-poker-search {
		.wrapper {
			padding: 10px;
		}

		.hgroup {
			margin-bottom: 20px;
		}

		.b-search {
			margin-bottom: 30px;
		}
	}
}
