.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1001;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	background-color: rgba(0,0,0,0.5);
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
	width: 295px;
	margin: 50px auto;
	background-color: #ffffff;
}

.modal-content {
	position: relative;
	outline: 0;
}

.modal-body {
	position: relative;
	padding: 20px;
	color: #918f8f;
}

.modal-body-full {
	padding: 0;
}

.modal-header {
	border-bottom: 1px solid #8d0000;
	padding: 20px 20px 10px 10px;
	color: #8d0000;
	font-size: 17px;
	font-weight: bold;
	overflow: hidden;


	.icon-close {
		fill:#828282;
		float: right;
		width: 15px;
		height: 15px;
		margin-left: 10px;
		margin-top: 1px;
		cursor: pointer;
	}
}

.modal-body__message {
	font-size: 13px;
	color: #918f8f;

	p {
		margin: 0;

		+ p {
			margin-top: 5px;
		}
	}
}


.modal-footer {
	border-top: 1px solid #d8d8d8;
	font-size: 0;
	overflow: hidden;

	.btn {
		font-size: 12px;
		float: left;
		width: 50%;
		color: #000;
		background-color: #fff;
		border: none;
		border-right: 1px solid #d8d8d8;

		&:last-child {
			border-right: 0;
		}
	}
}



.modal-fullscreen {
	text-align: center;

	.modal-dialog {
		width: 100%;
		height: 100%;
		margin: 0;
		background-color: transparent;
	}

	.img-fullscreen {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
	}
}


.block-table {
	display: table;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.block-middle {
	display: table-cell;
	vertical-align: middle;
}


.modal-close {
	cursor: pointer;
	position: fixed;
	bottom: 30px;
	right: 10px;
	z-index: 201;
	width: 44px;
	height: 44px;
	display: block;
	border: none;
	overflow: hidden;
	background-color: transparent;
	font-size: 0;
}


.wrapper-map {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.map {
		width: 100%;
		height: 100%;
	}
}


.link-detect {
	border-radius: 50%;
	background-color: rgb( 255, 255, 255 );
	box-shadow: 1px 1.732px 2px 0px rgb( 1, 2, 2 );
	width: 45px;
	height: 45px;
	position: fixed;
	top: 80px;
	line-height: 45px;
	text-align: center;
	right: 10px;
	z-index: 200;
}

.icon-detect {
	fill:#373737;
	display: inline-block;
	vertical-align: middle;
	width: 28px;
	height: 28px;
	overflow: hidden;
}
