* {
	@include box-sizing(border-box);
}

*:before,
*:after {
	@include box-sizing(border-box);
}

*:focus {
	outline: none;
}

* {
	-webkit-tap-highlight-color: transparent;
}

html {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	background-color: $bg-color-default;
	-webkit-font-smoothing: antialiased;
	font-family: $font-default;
	font-size: 12px;
}

body.mobileApp {
	background-color: #fff;
}

a {
	text-decoration: none;
	color: $link-color;

	&:hover {
		text-decoration: none;
	}
}

input[type="submit"] {
	&.btn {
		border-radius: 0;
	}
}

.no-gutter > [class*='col-'] {
	padding-right:0;
	padding-left:0;
}

.svgstore {
	display: none;
	height: 0;
	overflow: hidden;
}

.layout-body {
	background: #fff;
	position: relative;
}

.apps {
	text-align: right;
	font-size: 0;
}

.app-store {
	display: inline-block;
	vertical-align: top;
	width: 125px;
	height: 43px;
	background: url(../images/appstore.png) no-repeat;
	background-size: 125px 43px;
	margin-left: 26px;
}

.google-play {
	display: inline-block;
	vertical-align: top;
	width: 129px;
	height: 43px;
	background: url(../images/google-play.png) no-repeat;
	background-size: 129px 43px;
}

.layout {
	position: relative;
	padding-top: 50px;
}


.headline-color{
	display: block;
	background-color: $bg-headline-color;
	padding: 15px 10px 15px 10px;
	border-bottom: 1px solid #90191c;
	overflow: hidden;

	.h2,
	.h3 {
		line-height: 1;
		display: inline-block;
		vertical-align: top;
		font-weight: bold;
	}

	.h2 {
		font-size: 17px;
		color: #90191c;
	}

	.h3 {
		font-size: 13px;
		color: #231f27;
	}


	.link {
		font-size: 11px;
		color: #bfbfbf;
		display: inline-block;
		padding-top: 4px;
	}

	.icon {
		fill:#8D0000;
		width: 20px;
		height: 20px;
		float: left;
		margin: -2px 5px 0 0;

		&.right {
			float: right;
		}

		&.icon-map {
			width: 30px;
			height: 30px;
			margin-top: -10px;
		}
	}
}


.headline-color_success {
	background: #e8ebf0;
	border-bottom: 1px solid #d8d8d8;
}

.panel-action {
	padding: 10px 0;
	font-size: 13px;
	line-height: 1.1;
	overflow: hidden;
	font-weight: bold;
	background-color: #e8ebf0;
	border-bottom: 1px solid #dbdbdb;
	display: table;
	width: 100%;

	p {
		margin: 0;
		overflow: hidden;
		display: table-cell;
		vertical-align: baseline;
		min-height: 30px;
	}

	.icon {
		display: inline-block;
		vertical-align: middle;
		fill:#8D0000;
		width: 30px;
		height: 30px;


		&.icon-filter {
			width: 25px;
			height: 25px;
		}
	}
}

.panel-action__item {
	display: table-cell;
	vertical-align: middle;

	&.item-icon {
		width: 45px;
		padding: 0 10px;
	}
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}


.left {
	float: left;
}

.right {
	float: right;
}


.list-countries {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 14px;
	font-weight: bold;

	li {
		border-bottom: 1px solid #d8d8d8;
	}

	a {
		display: block;
		padding: 15px 10px;
		color: #231f27;
		overflow: hidden;
	}

	.flag-icon {
		float: left;
		margin: 1px 12px 0 0;
		width: 20px;
		height: 16px;
		border:1px solid #e2e2e2;
	}
}


.icon-filter {
	fill:#8d0000;
	width: 25px;
	height: 25px;
	display: inline-block;
	vertical-align: middle;
}


.sticker {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	text-align: center;
	height: 16px;
	background: #a100ff;
	margin-left: 3px;
	font-size: 0;

	.icon {
		fill:#fff;
		width: 20px;
		height: 16px;
		display: inline-block;
		vertical-align: middle;
	}
}



.description-dropdown {
	display: none;
	margin-top: 5px;

	p {
		margin: 0;

		+ p {
			margin: 5px 0 0 0;
		}
	}
}

.column-arrow {
	padding-right: 20px;
	position: relative;


	.icon-click {
		position: absolute;
		top: -5px;
		right: 0;
		width: 25px;
		height: 25px;
		text-align: right;
		line-height: 25px;
		font-size: 0;

		.icon {
			display: inline-block;
			vertical-align: middle;
		}
	}

}

.form {
	+ .form {
		margin-top: 30px;
	}
}


.block-content {
	padding: 0 10px;
	font-size: 13px;
	color: #231f27;
	border-bottom: 1px solid #d8d8d8;
}
